import NavigationMenuAPI from "./resources/navigation-menu";
import CategoriesAPI from "~/services/categories";
import { ORDER, SORT } from "~/constants";
import useBreakpointsLimit from "~/hooks/use-breakpoints-limit";

const useLimit = useBreakpointsLimit({ sm: 6, md: 6, lg: 8, xl: 12, xl2: 12, xl3: 16, xl4: 22, xl5: 16, xl6: 16 });
const appConfig = useRuntimeConfig().public;
const isLeagueView = appConfig.APP_CUSTOMER.enable_leagues_view;

const categoryLinkPath = (id: string) => `/category/${id}`;
const leagueLinkPath = (id: string) => `/league/${id}`;
const teamLinkPath = (id: string) => `/team/${id}`;

const formatLink = (options: { id: string; title: string; imageUrl?: string; href?: string }): IHeaderLink => {
  const path = options.href || (isLeagueView ? leagueLinkPath(options.id) : categoryLinkPath(options.id));

  return {
    id: options.id,
    title: options.title,
    imageUrl: options.imageUrl,
    href: path,
  };
};

const formatLeagueLink = (options: { id: string; title: string; href?: string; sublinks: any[] }) => {
  const links = formatLink({ id: options.id, title: options.title, href: options.href });

  links.subLinks = options.sublinks
    .slice(0, useLimit.value)
    .sort((a, b) => a.title.localeCompare(b.title))
    .map((subLink) => {
      return formatLink({ id: subLink._id, title: subLink.title, imageUrl: subLink.logo, href: teamLinkPath(subLink._id) });
    });

  links.hasMore = options.sublinks.length > useLimit.value;

  return links;
};

const HeaderAPI = {
  async load() {
    const customHeaderLinks = appConfig.APP_CUSTOMER.header_links as unknown as Link[] || [];
    const cache = useSessionStorage<IHeaderLink[]>(`${appConfig.APP_CUSTOMER.name_short}-links`, []);
    let links: IHeaderLink[] = [];

    const archivedLink = {
      id: "archive",
      title: "Archive",
      href: "/archive/",
      subLinks: [] as IHeaderLink[],
    };

    const customLinks = customHeaderLinks.map(link => ({ id: link.title, title: link.title, href: link.url }));

    if (cache.value && cache.value.length) {
      links = [...cache.value];
    } else {
      if (isLeagueView) {
        links = await HeaderAPI.loadLeagueLinks();
        archivedLink.subLinks = await HeaderAPI.loadArchivedLeague();
      } else {
        links = await HeaderAPI.loadCategoryLinks();
      }

      if (customLinks.length > 0)
        links.unshift(...customLinks);

      if (archivedLink.subLinks.length > 0)
        links.push(archivedLink);

      cache.value = links;
    }

    return links;
  },

  loadCategoryLinks(): Promise<IHeaderLink[]> {
    return CategoriesAPI
      .load({ page: 0, pageSize: 50, order: ORDER.asc, sort: SORT.order })
      .then(response => response.content.entries)
      .then(categories => categories.map(category => formatLink({ id: category.id, title: category.title })))
      .catch((err) => {
        console.error(err);
        return [] as IHeaderLink[];
      });
  },

  loadLeagueLinks(): Promise<IHeaderLink[]> {
    return NavigationMenuAPI
      .load()
      .then(response => response.content.entries)
      .then(leagues => leagues.map(league => formatLeagueLink({ id: league.id, title: league.title, sublinks: league.teams })))
      .catch((err) => {
        console.error(err);
        return [] as IHeaderLink[];
      });
  },

  loadArchivedLeague(): Promise<IHeaderLink[]> {
    return CategoriesAPI
      .load({
        archived: true,
        page: 0,
        pageSize: useLimit.value,
        order: ORDER.asc,
        sort: SORT.order,
      })
      .then(response => response.content.entries)
      .then(archivedLeague => archivedLeague.map((league) => {
        return formatLink({
          id: league.id,
          title: league.title,
          imageUrl: league.imageUrl,
          href: `/league/${league.id}`,
        });
      }))
      .catch((err) => {
        console.error(err);
        return [] as IHeaderLink[];
      });
  },

  clearCachedLinks() {
    const cache = useSessionStorage<IHeaderLink[]>(`${appConfig.APP_CUSTOMER.name_short}-links`, []);
    cache.value = [];
  },
};

export default HeaderAPI;
